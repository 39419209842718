<template>
  <div>
    <iq-card>
      <b-modal v-model="modalShow">
        <b-overlay :show="overlay" rounded="sm">
          <label for="itemsLimit">Ingrese el total de items en alegra</label>
          <b-form-input id="itemsLimit" type="number" v-model="itemsLimit"></b-form-input>
        </b-overlay>
        <template #modal-footer>
          <b-button :disabled="btns" variant="danger" >Cancelar</b-button>
          <b-button :disabled="btns" variant="success" @click="searchItems">Actualizar Items</b-button>
        </template>
      </b-modal>
      <template v-slot:headerTitle>
        <h4 class="card-title">Items de venta</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="success" class="ml-2" @click="modalShow =! modalShow">Buscar Productos</b-button>
      </template>
      <div class="mt-10"  >
        <ag-grid-vue
          id="ag-grid"
          class="ag-theme-material border height-500"
          :enableCellTextSelection="true"
          :columnDefs="contactsHeaders"
          :gridOptions="gridOptions"
          :defaultColDef="defaultColDef"
          :rowData="contactsData"
          :floatingFilter="true"
          :modules="modules"
          rowSelection="single"
          :resizable="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          style="width: 100%; height: 70vh;"
        ></ag-grid-vue>
      <!-- <b-table :items="sale.items" ></b-table> -->
      </div>
    </iq-card>
  </div>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AgGridVue } from 'ag-grid-vue'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import axios from 'axios'
import { core } from '@/config/pluginInit'
import { db } from '@/config/firebase'
const basicAuth = 'Basic YXBveW9sb2dpc3RpY29waEBnbWFpbC5jb206MjI2YzFiZDk2NTM0YzczODE2ZmU='

function currencyFormatter (params) {
  return '\x24' + formatNumber(params.value)
}

function formatNumber (number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}
// import firebase from 'firebase'
// import axios from 'axios'
// const basicAuth = 'Basic YXBveW9sb2dpc3RpY29waEBnbWFpbC5jb206MjI2YzFiZDk2NTM0YzczODE2ZmU='
export default {
  name: 'Items',
  data () {
    return {
      // Ag-Grid Data
      modules: AllCommunityModules,
      contactsInfo: null,
      gridApi: null,
      gridOptions: null,
      contactsData: [],
      contactsHeaders: [],
      paginationPageSize: 10,
      defaultColDef: { resizable: true },
      modalShow: false,
      overlay: false,
      itemsLimit: 0,
      btns: false,
      crudAlegraItems: [],
      AlegraItems: {}

    }
  },
  components: {
    AgGridVue
  },
  beforeMount () {
    this.gridOptions = {}
    this.contactsHeaders = [
      { headerName: 'Id', field: 'id', sortable: true, filter: true },
      { headerName: 'Nombre ', field: 'name', sortable: true, filter: true },
      { headerName: 'Precio', field: 'price.0.price', sortable: true, filter: true, valueFormatter: currencyFormatter },
      { headerName: 'Descripción', field: 'description', sortable: true, filter: true }
    ]
  },
  mounted () {
    core.index()
    this.getItemsIndex()
    this.gridApi = this.gridOptions.api
    console.log(Math.ceil(25.1))
  },
  methods: {
    // Alertas
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    getItemsIndex () {
      const vm = this
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/items')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                const data = doc.data()
                Object.values(data).forEach(function (value) {
                  vm.contactsData.push(value)
                })
              }
            })
          }
        })
    },
    async searchItems () {
      this.btns = true
      this.overlay = true
      console.log('voy a buscar')
      // const indexCount = Math.ceil(this.itemsLimit / 100)
      const querysCount = Math.ceil(this.itemsLimit / 30)
      let itemsCount = 0
      console.log(querysCount)
      for (let index = 0; index < querysCount; index++) {
        await axios({
          method: 'get',
          url: 'https://api.alegra.com/api/v1/items/?start=' + itemsCount + '&limit=30',
          headers: { Authorization: basicAuth }
        }).then((res) => {
          console.log(index, res)
          this.crudAlegraItems.push(res.data)
          itemsCount += 30
        }).catch((error) => {
          this.makeToast('danger', 'Error al consultar los items de Alegra. ' + error)
        })
      }
      let count = 0
      for (let index = 0; index < this.crudAlegraItems.length; index++) {
        for (let index2 = 0; index2 < this.crudAlegraItems[index].length; index2++) {
          const data = this.crudAlegraItems[index]
          const stringCount = count.toString()
          this.AlegraItems[stringCount] = data[index2]
          count++
        }
      }

      db.collection('campus/Vs2FkUx38u3W8IDuABNF/items').doc('itemsIndex')
        .set(this.AlegraItems)
        .then(() => {
          this.makeToast('success', 'Items actualizados.')
          this.overlay = false
          this.btns = false
        }).catch((error) => {
          this.makeToast('danger', 'Error al consultar los items de Alegra. ' + error)
        })
      this.modalShow = false
    }
  }
}
</script>
